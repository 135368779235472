exports.onRouteUpdate = ({ location }) => {
  anchorScroll(location)
  return true
}

exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  anchorScroll(location)
  return true
}

function anchorScroll(location) {
  // Check for location so build does not fail
  if (location && location.hash) {
    setTimeout(() => {
      // document.querySelector(${location.hash}).scrollIntoView({ behavior: 'smooth', block: 'start' });
      const item = document.querySelector(location.hash).offsetTop
      const mainNavHeight = 100 //document.querySelector(nav).offsetHeight;
      window.scrollTo({
        top: item - mainNavHeight,
        left: 0,
        behavior: 'smooth'
      })
    }, 0)
  }
}
