import { base } from '@theme-ui/presets'
import '../styles/global.css'

const systemFonts =
  'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif'

export default {
  ...base,
  colors: {
    background: '#fff',
    muted: '#f6f6f6',
    primary: '#EE6F6F',
    secondary: '#30c',
    text: '#1E0422',
    heading: '#EE6F6F',
    gray: '#3F3D3D',
    darkGray: '#3A3939',
  },
  fonts: {
    ...base,
    body: `alpina-light, ${systemFonts}`,
    heading: `alpina-extended, ${systemFonts}`,
    small: `pirelli, ${systemFonts}`
  },
  text: {
    small: {
      fontFamily: 'small',
      textTransform: 'uppercase',
      color: 'text',
      lineHeight: 'heading',
      letterSpacing: '0.035em'
    },
    heading: {
      fontFamily: 'heading',
      color: 'heading',
      lineHeight: 'heading',
      fontSize: [4,5,6]
    }
  },
  buttons: {
    ...base.buttons,
    icon: {
      width: '4em',
      height: '4em'
    }
  },
  styles: {
    ...base.styles,
    root: {
      ...base.styles.root,
      textSizeAdjust: '100%',
      fontKerning: 'normal',
      fontSynthesis: 'none',
    }
  }
}
